import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import { NoPermissionPage } from './no-permission.page';

@NgModule({
  declarations: [NoPermissionPage],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: ROUTER_UTILS.config.errorResponse.nopermission,
        component: NoPermissionPage,
        data: {
          title: 'The page you were looking for could not be accessed',
          robots: 'noindex, nofollow',
        },
      },
    ]),
  ],
})
export class NopermissionModule {}
