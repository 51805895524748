import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs';
import { environment } from '@env/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }

  GetMerchants() {
    return this.http.get(environment.apiUrl + 'merchants', { params: { 'limit': 100 } })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetDraftMerchants() {
    return this.http.get(environment.apiUrl + 'merchants/draft', { params: { 'limit': 100 } })
      .pipe(map((draftResponse: any) => {
        return draftResponse;
      }));
  }

  getOfflineMerchants() {
    return this.http.get(environment.apiUrl + 'merchants', { params: { 'limit': 100, 'status': 'Offline' } })
      .pipe(map((offlineResponse: any) => {
        return offlineResponse;
      }));
  }

  getVoucherCodes(query: any) {
    return this.http.get(environment.apiUrl + 'codes', { params: query })
      .pipe(map((res: any) => {
        return res;
      }));
  }

  issueVoucherCodes(query: any) {
    return this.http.get(environment.apiUrl + 'codes/manual-issue', { params: query })
      .pipe(map((res: any) => {
        return res;
      }));
  }

  inactiveVoucherCodes(query: any) {
    return this.http.get(environment.apiUrl + 'codes/manual-inactive', { params: query })
      .pipe(map((res: any) => {
        return res;
      }));
  }

  GetMerchantById(id: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'merchants', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetDraftMerchantById(id: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'merchants/draft', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  SubmitMerchant(merchantNameTH: string, merchantNameEN: string, type: string, logo: File, formValue: any) {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("nameTh", merchantNameTH);
    formData.append("nameEn", merchantNameEN);
    formData.append("file", logo);
    formData.append("channels", JSON.stringify(formValue.channels));
    formData.append("currencies", JSON.stringify(formValue.currencies));
    return this.http.post(environment.apiUrl + 'merchants', formData).pipe((res) => res);
  }

  UpdateMerchant(id: string, status: string, merchantNameTH: string, merchantNameEN: string, type: string, logo: File, formValue: any) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("status", status);
    formData.append("type", type);
    formData.append("nameTh", merchantNameTH);
    formData.append("nameEn", merchantNameEN);
    formData.append("file", logo);
    formData.append("channels", JSON.stringify(formValue.channels));
    formData.append("currencies", JSON.stringify(formValue.currencies));
    return this.http.post(environment.apiUrl + 'merchants/update', formData).pipe((res) => res);
  }

  ApproveMerchant(id: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'merchants/approve', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  RejectMerchant(id: string, comment: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    const formData = new FormData();
    formData.append("comment", comment);
    return this.http.post(environment.apiUrl + 'merchants/reject', formData, opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  DeleteDraftMerchant(id: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'merchants/delete', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  ArchiveMerchant(id: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'merchants/archive', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  PublishMerchant(id: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'merchants/publish', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  UnpublishMerchant(id: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'merchants/unpublish', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  CreatePlatform(body: any) {
    return this.http.post(environment.apiUrl + 'platforms', body).pipe((res) => res);
  }

  CreateCurrency(body: any) {
    return this.http.post(environment.apiUrl + 'currencies', body).pipe((res) => res);
  }

  UpdateCurrency(body: any) {
    return this.http.patch(environment.apiUrl + 'currencies', body).pipe((res) => res);
  }

  CreateChannel(body: any) {
    return this.http.post(environment.apiUrl + 'channels', body).pipe((res) => res);
  }

  UpdateChannel(body: any) {
    return this.http.patch(environment.apiUrl + 'channels', body).pipe((res) => res);
  }

  CreateCampaign(merchant: any, campaignNameTh: string, campaignNameEn: string) {
    const formData = new FormData();
    formData.append("merchant", JSON.stringify(merchant));
    formData.append("campaignNameTh", campaignNameTh);
    formData.append("campaignNameEn", campaignNameEn);
    console.log('CreateCampaign', formData);
    return this.http.post(environment.apiUrl + 'campaigns', formData).pipe((res) => res);
  }

  getCampaigns() {
    return this.http.get(environment.apiUrl + 'campaigns')
      .pipe(map((response: any) => {
        return response;
      }));
  }

  getCampaignsByMerchantId(id: string) {
    const opts = { params: new HttpParams({ fromString: `merchantId=${id}` }) };
    return this.http.get(environment.apiUrl + 'campaigns', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetDeals() {
    return this.http.get(environment.apiUrl + 'deals', { params: { 'limit': 100 } })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetDraftDeals() {
    return this.http.get(environment.apiUrl + 'deals/draft', { params: { 'limit': 100 } })
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetDealsByMerchantId(id: string) {
    const opts = { params: new HttpParams({ fromString: `merchantId=${id}&status=Published,Offline` }) };
    return this.http.get(environment.apiUrl + 'deals', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  AddCodes(merchantId: string, dealId: string, codeList: File) {
    const formData = new FormData();
    formData.append("merchantId", merchantId);
    formData.append("dealId", dealId);
    formData.append("file", codeList);
    return this.http.post(environment.apiUrl + 'codes', formData).pipe((res) => res);
  }

  SubmitDeal(form: any, bg: File, thumbnail: File, merchant: any) {
    const formData = new FormData();
    formData.append("abcDealCode", `${form.campaign.substring(0, 3).toUpperCase()}ABC`);
    formData.append("tncTh", form.tncTh);
    formData.append("tncEn", form.tncEn);
    formData.append("titleTh", form.titleTh);
    formData.append("titleEn", form.titleEn);
    formData.append("taglineTh", form.taglineTh);
    formData.append("taglineEn", form.taglineEn);
    formData.append("thumbnailFile", thumbnail);
    formData.append("bgPictureFile", bg);
    formData.append("campaignType", form.campaign);
    formData.append("descEn", form.descEn);
    formData.append("descTh", form.descTh);
    formData.append("priceAbc", form.price);
    formData.append("price", form.amount);
    formData.append("initalDealQuota", form.quota);
    formData.append("endDateTime", form.enddate);
    formData.append("startDateTime", form.startdate);
    formData.append("merchant", JSON.stringify(merchant));
    formData.append("classification", JSON.stringify(form.classification));
    formData.append("dealType", form.dealType);
    formData.append("channels", JSON.stringify(form.channel));
    formData.append("currencies", JSON.stringify(form.currency));
    formData.append("dealLimit", 
      JSON.stringify(
        this.CreateDealConfig(
          this.DefaultNum(form.maxRedeemDealUserPerMonth), 
          this.DefaultNum(form.maxRedeemDealPerMonth),
          this.DefaultNum(form.maxRedeemDealUserPerDay),
          this.DefaultNum(form.maxRedeemDealPerDay)
          )));
    return this.http.post(environment.apiUrl + 'deals', formData).pipe((res) => res);
  }

  DefaultNum(num: any, defaultVal: number = -1){
    if(num === '') return defaultVal;

    return num;
  }

  CreateDealConfig(maxRedeemDealUserPerMonth: number, maxRedeemDealPerMonth: number, maxRedeemDealUserPerDay: number, maxRedeemDealPerDay: number){
    return {
      "globalLimit": {
        "daily": maxRedeemDealPerDay,
        "monthly": [
          {
            "monthOfYear": 1,
            "limit": maxRedeemDealPerMonth
          },
          {
            "monthOfYear": 2,
            "limit": maxRedeemDealPerMonth
          },
          {
            "monthOfYear": 3,
            "limit": maxRedeemDealPerMonth
          },
          {
            "monthOfYear": 4,
            "limit": maxRedeemDealPerMonth
          },
          {
            "monthOfYear": 5,
            "limit": maxRedeemDealPerMonth
          },
          {
            "monthOfYear": 6,
            "limit": maxRedeemDealPerMonth
          },
          {
            "monthOfYear": 7,
            "limit": maxRedeemDealPerMonth
          },
          {
            "monthOfYear": 8,
            "limit": maxRedeemDealPerMonth
          },
          {
            "monthOfYear": 9,
            "limit": maxRedeemDealPerMonth
          },
          {
            "monthOfYear": 10,
            "limit": maxRedeemDealPerMonth
          },
          {
            "monthOfYear": 11,
            "limit": maxRedeemDealPerMonth
          },
          {
            "monthOfYear": 12,
            "limit": maxRedeemDealPerMonth
          }
        ],
        "period": {
          "limit": -1
        }
      },
      "userLimit": {
        "daily": maxRedeemDealUserPerDay,
        "monthly": [
          {
            "monthOfYear": 1,
            "limit": maxRedeemDealUserPerMonth
          },
          {
            "monthOfYear": 2,
            "limit": maxRedeemDealUserPerMonth
          },
          {
            "monthOfYear": 3,
            "limit": maxRedeemDealUserPerMonth
          },
          {
            "monthOfYear": 4,
            "limit": maxRedeemDealUserPerMonth
          },
          {
            "monthOfYear": 5,
            "limit": maxRedeemDealUserPerMonth
          },
          {
            "monthOfYear": 6,
            "limit": maxRedeemDealUserPerMonth
          },
          {
            "monthOfYear": 7,
            "limit": maxRedeemDealUserPerMonth
          },
          {
            "monthOfYear": 8,
            "limit": maxRedeemDealUserPerMonth
          },
          {
            "monthOfYear": 9,
            "limit": maxRedeemDealUserPerMonth
          },
          {
            "monthOfYear": 10,
            "limit": maxRedeemDealUserPerMonth
          },
          {
            "monthOfYear": 11,
            "limit": maxRedeemDealUserPerMonth
          },
          {
            "monthOfYear": 12,
            "limit": maxRedeemDealUserPerMonth
          }
        ],
        "period": {
          "limit": -1
        }
      }
    }
  }

  UpdateDeal(id: string, form: any, bg: File, thumbnail: File, merchant: any, status: string) {

    const formData = new FormData();
    formData.append("id", id);
    formData.append("abcDealCode", `${form.campaign.substring(0, 3).toUpperCase()}ABC`);
    formData.append("tncTh", form.tncTh);
    formData.append("tncEn", form.tncEn);
    formData.append("titleTh", form.titleTh);
    formData.append("titleEn", form.titleEn);
    formData.append("taglineTh", form.taglineTh);
    formData.append("taglineEn", form.taglineEn);
    formData.append("thumbnailFile", thumbnail);
    formData.append("bgPictureFile", bg);
    formData.append("campaignType", form.campaign);
    formData.append("descEn", form.descEn);
    formData.append("descTh", form.descTh);
    formData.append("priceAbc", form.price);
    formData.append("price", form.amount);
    formData.append("initalDealQuota", form.quota);
    formData.append("endDateTime", form.enddate);
    formData.append("startDateTime", form.startdate);
    formData.append("merchant", JSON.stringify(merchant));
    formData.append("classification", JSON.stringify(form.classification));
    formData.append("dealType", form.dealType);
    formData.append("channels", JSON.stringify(form.channel));
    formData.append("currencies", JSON.stringify(form.currency));
    formData.append("status", status);
    formData.append("dealLimit", 
      JSON.stringify(
        this.CreateDealConfig(
          this.DefaultNum(form.maxRedeemDealUserPerMonth), 
          this.DefaultNum(form.maxRedeemDealPerMonth),
          this.DefaultNum(form.maxRedeemDealUserPerDay),
          this.DefaultNum(form.maxRedeemDealPerDay)
          )));

    return this.http.post(environment.apiUrl + 'deals/update', formData).pipe((res) => res);
  }

  GetDealById(id: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'deals', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetDraftDealById(id: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'deals/draft', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  ApproveDeal(id: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'deals/approve', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  RejectDeal(id: string, comment: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    const formData = new FormData();
    formData.append("comment", comment);
    return this.http.post(environment.apiUrl + 'deals/reject', formData, opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  DeleteDraftDeal(id: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'deals/delete', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  ArchiveDeal(id: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'deals/archive', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  PublishDeal(id: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'deals/publish', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  UnpublishDeal(id: string) {
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'deals/unpublish', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetChannelById(id: string) {
    var queries = { params: { id: id } };
    return this.http.get(environment.apiUrl + 'channels', queries)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetChannels(status: string) {
    var queries = { params: { 'limit': 100, status: '' } };
    if(status){
      queries.params.status = status;
    }
    return this.http.get(environment.apiUrl + 'channels', queries)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetAllowedChannel(id: string) {
    const opts = { params: new HttpParams({ fromString: `merchantId=${id}` }) };
    return this.http.get(environment.apiUrl + 'allowed-channels', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetCurrenciesOfAllowedChannel(id: string) {
    const opts = { params: new HttpParams({ fromString: `allowedChannelId=${id}` }) };
    return this.http.get(environment.apiUrl + 'currencies-of-allowed-channels', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetAuthorizedChannelByMerchantId(id: string) {
    const opts = { params: new HttpParams({ fromString: `merchantId=${id}` }) };
    return this.http.get(environment.apiUrl + 'authorized-channels', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  publishCurrency(id: string) {
    var queries = { params: { id: id } };
    return this.http.get(environment.apiUrl + 'currencies/publish', queries)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  unpublishCurrency(id: string) {
    var queries = { params: { id: id } };
    return this.http.get(environment.apiUrl + 'currencies/unpublish', queries)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  publishChannel(id: string) {
    var queries = { params: { id: id } };
    return this.http.get(environment.apiUrl + 'channels/publish', queries)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  unpublishChannel(id: string) {
    var queries = { params: { id: id } };
    return this.http.get(environment.apiUrl + 'channels/unpublish', queries)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetCurrencyById(id: string) {
    var queries = { params: { id: id } };
    return this.http.get(environment.apiUrl + 'currencies', queries)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetCurrencies(status: string) {
    var queries = { params: { 'limit': 100, status: '' } };
    if(status){
      queries.params.status = status;
    }
    return this.http.get(environment.apiUrl + 'currencies', queries)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetCurrenciesByChannelIds(channelIds: string) {
    const opts = { params: new HttpParams({ fromString: `channelIds=${channelIds}` }) };
    return this.http.get(environment.apiUrl + 'currencies-of-channels', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  GetCurrenciesByPlatformId(id: string) {
    const opts = { params: new HttpParams({ fromString: `platformId=${id}` }) };
    return this.http.get(environment.apiUrl + 'currencies', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  downloadCodeCSVFile(query: any): void{
    var opts: any = { params: query };
    opts.responseType = 'blob' as 'json';

    let currenDateStr = this.getCurrentDateTime();
    let filename = `vms-voucher-code-export-${currenDateStr}.csv`

    this.http.get(environment.apiUrl + 'codes/csv', opts).subscribe(
        (response: any) => {
            console.log('response.', response)
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            if (filename)
                downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }
    )
  }

  getConfigById(id: string){
    const opts = { params: new HttpParams({ fromString: `id=${id}` }) };
    return this.http.get(environment.apiUrl + 'configs', opts)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  updateConfig(body: any) {
    return this.http.patch(environment.apiUrl + 'configs', body).pipe((res) => res);
  }

  getCurrentDateTime () {
    let date_ob = new Date();
  
    // current date
    // adjust 0 before single digit date
    let date = ("0" + date_ob.getDate()).slice(-2);
  
    // current month
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
  
    // current year
    let year = date_ob.getFullYear();
  
    // current hours
    let hours = date_ob.getHours();
  
    // current minutes
    let minutes = date_ob.getMinutes();
  
    // current seconds
    let seconds = date_ob.getSeconds();
  
    return year + "-" + month + "-" + date + "_" + hours + "-" + minutes + "-" + seconds;
  }
}
