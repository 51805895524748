import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard, NoAuthGuard } from "@core/guards";
import { ROUTER_UTILS } from "@core/utils/router.utils";
import { NotFoundModule } from "@shell/ui/not-found/not-found.module";
import { FooterModule } from "./ui/footer/footer.module";
import { HeaderModule } from "./ui/header/header.module";
import { LayoutModule } from "./ui/layout/layout.module";
import { NotFoundPage } from "./ui/not-found/not-found.page";
import { LeftnavModule } from "./ui/leftnav/leftnav.module";
import { NopermissionModule } from "./ui/no-permission/no-permission.module";
import { NoPermissionPage } from "./ui/no-permission/no-permission.page";
import { UserRoleEnum } from "@core/utils/UserRoleEnum";

const APP_ROUTES: Routes = [
  {
    path: ROUTER_UTILS.config.auth.root,
    loadChildren: async () =>
      (await import("@pages/auth/auth.module")).AuthModule,
    canLoad: [NoAuthGuard],
  },
  {
    path: ROUTER_UTILS.config.base.home,
    loadChildren: async () =>
      (await import("@pages/home/home.module")).HomeModule,
  },
  {
    path: ROUTER_UTILS.config.base.dashboard,
    loadChildren: async () =>
      (await import("@pages/dashboard/dashboard.module")).DashboardModule,
    canActivate: [AuthGuard],
    data: {
      roles: [UserRoleEnum.Admin],
    },
  },
  {
    path: ROUTER_UTILS.config.settings.root,
    loadChildren: async () =>
      (await import("@pages/settings/settings.module")).SettingsModule,
    canActivate: [AuthGuard],
    data: {
      roles: [UserRoleEnum.Admin],
    },
  },
  {
    path: ROUTER_UTILS.config.user.root,
    loadChildren: async () =>
      (await import("@pages/user/user.module")).UserModule,
    canActivate: [AuthGuard],
    data: {
      roles: [UserRoleEnum.Admin],
    },
  },
  {
    path: ROUTER_UTILS.config.operation.root,
    loadChildren: async () =>
      (await import("@pages/operation/operation.module")).OperationModule,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoleEnum.Admin,
        UserRoleEnum.Operator,
        UserRoleEnum.WalletUser,
      ],
    },
  },
  {
    path: "no-permission",
    loadChildren: async () =>
      (await import("@shell/ui/no-permission/no-permission.module"))
        .NopermissionModule,
    component: NoPermissionPage,
  },
  {
    path: "**",
    loadChildren: async () =>
      (await import("@shell/ui/not-found/not-found.module")).NotFoundModule,
    component: NotFoundPage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(APP_ROUTES),
    FooterModule,
    HeaderModule,
    LayoutModule,
    LeftnavModule,
    NotFoundModule,
    NopermissionModule,
  ],
  exports: [
    RouterModule,
    FooterModule,
    HeaderModule,
    LeftnavModule,
    LayoutModule,
    NotFoundModule,
    NopermissionModule,
  ],
})
export class WebShellModule {}
