<header
  class="sticky top-0 w-full h-16 shadow-lg header bg-opacity-10 bg-neutral-50 dark:bg-neutral-700 backdrop-blur-lg backdrop-filter"
>
  <nav class="container flex items-center justify-end h-full py-3 mx-auto">
    <div class="col-2" style="margin: 0 0px;text-align: end;">
      <h2 style="margin: 0">
        <span class="pt-0 pr-0 badge">{{ name }}</span>
      </h2>
      <h4 style="margin-top: -10px">
        <span class="badge badge-secondary">{{ role }}</span>
      </h4>
    </div>
    
    <div class="flex items-center space-x-4">
      <button (click)="onClickSignOut()" class="btn btn-secondary abc-button-color">
        <svg
          class="w-6 h-6 mr-3"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          ></path>
        </svg>
        Sign out
      </button>
    </div>
  </nav>
</header>
