import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItem, removeItem, setItem, StorageItem } from '@core/utils';
import { environment } from '@env/environment.dev';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private http: HttpClient) { }
  
  isLoggedIn$ = new BehaviorSubject<boolean>(!!getItem(StorageItem.Auth));

  get isLoggedIn(): boolean {
    return this.isLoggedIn$.getValue();
  }

  signIn(form: any): void {
    let token = '';
    let name = '';
    let role = '';
    //!TODO: Just stub the login user for now
    this.http.post(environment.apiUrl + '/auth/login', {
      "email": form.email,
      "password": form.password
    })
            .pipe(map((response: any) => {
              console.log(response);
              
              return response;
            })).subscribe(data => {
              console.log(data);
              
              token = data.tokens.access.token;
              name = data.user.name;
              role = data.user.role;
              console.log(name);
              setItem(StorageItem.Name, name);
              setItem(StorageItem.Role, role);
              setItem(StorageItem.Auth, token);
              this.isLoggedIn$.next(true);
            });
    
  }

  signOut(): void {
    removeItem(StorageItem.Auth);
    removeItem(StorageItem.Name);
    removeItem(StorageItem.Role);
    this.isLoggedIn$.next(false);
  }
}
