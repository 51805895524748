<div class="wrapper">
  <!-- Sidebar -->
  <nav id="sidebar" class="fixed bg-white">
    <div class="flex items-center justify-center # border-bottom header-icon">
      <a
        [routerLink]="[path.base.home]"
        class="text-2xl font-bold cursor-pointer text-decoration-none link-dark"
      >
        <img src="../../../../assets/logos/ABC final logo-rgb.png" alt="logo" />
      </a>
    </div>

    <ul class="list-unstyled components active">
      <li>
        <ul class="list-unstyled" id="operationSubmenu">
          <li>
            <a
              class="text-sm link"
              [routerLink]="['/', path.operation.root, path.operation.createCurrency]"
            >
              <span class="fa-solid fa-circle-plus"></span>
              <span class="badge">Create Currency</span>
            </a>
          </li>
          <li>
            <a
              class="text-sm link"
              [routerLink]="['/', path.operation.root, path.operation.viewCurrency]"
            >
              <span class="fa-solid fa-magnifying-glass"></span>
              <span class="badge">View Currency</span>
            </a>
          </li>
          <li>
            <a
              class="text-sm link"
              [routerLink]="['/', path.operation.root, path.operation.createChannel]"
            >
              <span class="fa-solid fa-circle-plus"></span>
              <span class="badge">Create Channel</span>
            </a>
          </li>
          <li>
            <a
              class="text-sm link"
              [routerLink]="['/', path.operation.root, path.operation.viewChannel]"
            >
              <span class="fa-solid fa-magnifying-glass"></span>
              <span class="badge">View Channel</span>
            </a>
          </li>
          <li>
            <a
              class="text-sm link"
              [routerLink]="[
                '/',
                path.operation.root,
                path.operation.createMerchant
              ]"
            >
              <span class="fa-solid fa-circle-plus"></span>
              <span class="badge">Create Merchant</span>
            </a>
          </li>
          <li>
            <a
              class="text-sm link"
              [routerLink]="[
                '/',
                path.operation.root,
                path.operation.viewMerchant
              ]"
            >
              <span class="fa-solid fa-magnifying-glass"></span
              ><span class="badge">View Merchant</span>
            </a>
          </li>
          
          <li>
            <a
              class="text-sm link"
              [routerLink]="[
                '/',
                path.operation.root,
                path.operation.createDeal
              ]"
            >
              <span class="fa-solid fa-circle-plus"></span
              ><span class="badge">Create Deal</span>
            </a>
          </li>
          <li>
            <a
              class="text-sm link"
              [routerLink]="['/', path.operation.root, path.operation.viewDeal]"
            >
              <span class="fa-solid fa-magnifying-glass"></span>
              <span class="badge">View Deal</span>
            </a>
          </li>
          <li>
            <a
              class="text-sm link"
              [routerLink]="['/', path.operation.root, path.operation.loadCode]"
            >
              <span class="fa-solid fa-circle-plus"></span
              ><span class="badge">Load Voucher Code</span>
            </a>
          </li>
          <li>
            <a
              class="text-sm link"
              [routerLink]="['/', path.operation.root, path.operation.viewCode]"
            >
              <span class="fa-solid fa-magnifying-glass"></span
              ><span class="badge">View Voucher Code</span>
            </a>
          </li>
          <li>
            <a
              class="text-sm link"
              [routerLink]="[
                '/',
                path.operation.root,
                path.operation.loadCampaign
              ]"
            >
              <span class="fa-solid fa-circle-plus"></span>
              <span class="badge">Load Campaign</span>
            </a>
          </li>
          <li>
            <a
              class="text-sm link"
              [routerLink]="[
                '/',
                path.operation.root,
                path.operation.editDealSortingConfig
              ]"
            >
              <span class="fa-solid fa-circle-plus"></span>
              <span class="badge">Manage Classification</span>
            </a>
          </li>
          
        </ul>
      </li>
    </ul>
  </nav>
</div>
