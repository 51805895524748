import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private readonly keycloak: KeycloakService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    return this.checkUserLogin(next, url);
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): Promise<boolean> {

    return this.keycloak.isLoggedIn().then((res) => {
      let role = this.keycloak.getUserRoles(false);
      console.log(route.data?.['roles']);
       
      if (res) {
        if (role.some((r) => {return route.data?.['roles'].includes(r)})) {
          console.log('match');
          return true;
        }
        this.router.navigate(['/no-permission']);
        return false;
      }
      this.router.navigate(['/logout']);
      return false;
    });
  }
}
