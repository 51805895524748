import { Component, OnInit } from '@angular/core';
import { removeItem, StorageItem } from '@core/utils';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  isLoggedIn: boolean = false;
  constructor(
    private readonly keycloak: KeycloakService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    removeItem(StorageItem.Auth);
    removeItem(StorageItem.Name);
    removeItem(StorageItem.Role);
  }

}
