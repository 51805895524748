import { Component } from '@angular/core';
import { ROUTER_UTILS } from '@core/utils/router.utils';

@Component({
  templateUrl: './no-permission.page.html',
  styleUrls: ['./no-permission.page.css'],
})
export class NoPermissionPage {
  path = ROUTER_UTILS.config.base;
}
