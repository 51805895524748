import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  path = ROUTER_UTILS.config.base;
  name: any = '';
  role: any = '';
  constructor(private readonly keycloak: KeycloakService,
    private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.keycloak.loadUserProfile(true).then((res) => {
      this.name = res?.firstName + ' ' + res?.lastName;
      this.ref.detectChanges();
    }).catch((err) => {
      console.error(err);
    });
    this.role = this.keycloak.getUserRoles(false).filter((x) => x !== 'wallet_user')[0];
  }

  onClickSignOut(): void {
    this.keycloak.logout(window.location.origin + '/');
  }


}
