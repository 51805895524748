// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api-hwc.abc-dev.network/vms-light-api/v1/',
  defaultUser: 'fake@example.com',
  keycloak: {
    url: 'https://abc-auth-hw.abc-dev.network',
    realm: 'master',
    clientId: 'vms-light'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
